<template>
  <div class="app">
    <el-card class="box-card">
        <el-col :span="12" style="margin-bottom:12px">
              <el-popover
                placement="top"
                width="160"
                v-model="delAllStatus"
                style="margin-right: 8px;"
              >
                <p>您確認要清空日誌嗎？</p>
                <div style="text-align: right; margin: 0">
                  <el-button size="mini" type="text" @click="delAllStatus = false">取消</el-button>
                  <el-button type="primary" size="mini" @click="emptyLogsAll">確定</el-button>
                </div>
                <el-button size="small" type="danger" slot="reference">
                  <i class="fa fa-trash-o"></i> 批量清空
                </el-button>
              </el-popover>

              <el-button
                size="small"
                type="info"
                @click="$refs.RoomTable.clearSelection()"
                style="margin-right: 8px;"
              >取消選擇</el-button>
        </el-col>

        <!--列表-->
        <el-table
          class="lists"
          ref="RoomTable"
          :data="lists.list"
          :height="lists.total?clientHeight:'100'"
          v-loading="loading"
          @selection-change="handleSelectionChange"
          border
          style="width: 100%;"
        >
          <el-table-column fixed type="selection" width="40"></el-table-column>
          <el-table-column fixed prop="account" label="用戶名稱" width="90"></el-table-column>
          <el-table-column fixed prop="clientIP" min-width="120" label="登入IP"></el-table-column>
          <el-table-column prop="module" min-width="120" label="模塊"></el-table-column>

          <el-table-column prop="location" min-width="120" label="登入位置"></el-table-column>
          <el-table-column prop="browser" label="瀏覽器" width="120"></el-table-column>
          <el-table-column prop="clientOS" label="操作系統" width="170"></el-table-column>
          <el-table-column label="請求代碼" width="100">
            <template slot-scope="scope">
              <div style="text-align: center;">
                <el-link :type="scope.row.statusCode==200?'success':'danger'">
                  {{scope.row.statusCode}}
                </el-link>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="reqInfo" label="操作信息" width="180"></el-table-column>
          <el-table-column prop="reqUri" label="請求接口" width="180"></el-table-column>
          <el-table-column fixed="right" prop="startTime" label="登入時間" width="180"></el-table-column>
        </el-table>

        <div class="pager">
          <el-pagination
            background
            layout="prev, pager, next"
            @current-change="pageBtn"
            :page-size="lists.pageSize"
            :total="lists.total"
          ></el-pagination>
        </div>


    </el-card>
  </div>
</template>

<script>
export default {
  data () {
    return {
      clientHeight:800,
      loading:false,
      query:'',
      type:'0',
      lists: {
        total: 0,
        pageSize: 0,
        list: [],
      },
      page:1,
      multipleSelection: [],
      delAllStatus:false,
    }
  },
  mounted(){
    this.getList()
  },
  methods:{
    // 选择用户
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 清空已选择日志
    emptyLogsAll() {
      let that = this;
      let length = that.multipleSelection.length;
      if (length <= 0) {
        that.$notify({
          title: "清空提示",
          message: "请勾选清空日志",
          type: "warning",
        });
        that.delAllStatus = false;
        return;
      }
      let idList = [];
      for (let i in that.multipleSelection) {
        idList[i] = that.multipleSelection[i].id;
      }
      that.emptyLogs(idList);
      that.delAllStatus = false;
    },
    // 清空日志
    emptyLogs(arrayId) {
      let that = this;
      let param = that.$atk.json_to_url({
        ids: arrayId,
      });
      that.$http.DELETE(
        "/v1/admin/logs/emptyLogs?" + param,
        function (ret) {
          if (ret.errcode == 0) {
            that.$notify({
              title: "成功",
              message: ret.errmsg,
              type: "success",
            });
            for (let key in arrayId) {
              that.deleteItem(arrayId[key], that.lists.list);
            }
          } else if (ret.errcode >= 1) {
            that.$notify.error({
              title: "错误",
              message: ret.errmsg,
            });
          }
        },
        function (err) {
          that.$notify({
            title: "警告",
            message: err,
            type: "warning",
          });
        }
      );
    },
    // 删除列表日志
    deleteItem(item, list) {
      for (let key in this.lists.list) {
        if (list[key].id === item) {
          list.splice(key, 1);
        }
      }
    },
    // 分页
    pageBtn(val) {
      this.page = val;
      this.getList(val);
    },
    // 获取账户列表
    getList(page = 0) {
      let that = this;
      let param = that.$atk.json_to_url({
        page: page || parseInt(that.page),
        type: parseInt(that.type),
        query: that.query,
      });
      that.loading = true;
      this.$http.GET(
        "/v1/admin/logs/getActionList?" + param,
        function (ret) {
          if (ret.errcode == 0) {
            let data = ret.data || [];
            that.lists = data;
            that.loading = false;
          } else if (ret.errcode == 1) {
            that.loading = false;
            that.$message({
              message: ret.errmsg,
              type: "warning",
            });
          }
        },
        function (err) {
          that.loading = false;
          that.$notify({
            title: "警告",
            message: err,
            type: "warning",
          });
        }
      );
    },
  }
}
</script>
<style scoped lang="scss">
</style>
